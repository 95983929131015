
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


/*body {
  color: #242424;
}

a {
  color: #dd0c08;
}

.navbar-default {
  background-color: #ffffff;
  border-color: #242424;
}

.navbar-default .navbar-nav > li > a {
  color: #242424;
}

.btn-primary {
  background-color: #dd0c08;
  border-color: #dd0c08;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
  background-color: #dd0c08 !important;
  border-color: #dd0c08 !important;
}*/

.fa-btn {
  margin-right: 6px;
}

.section-header {
  border-top: 1px solid #ddd;
  margin-top: 10px;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-l-10 {
  margin-left: 10px;
}

.user-confirmed {
  color: #2ca02c;
}

.user-unconfirmed {
  color: #c9302c;
}
.btn-group{

}

.section-header h4{
  font-weight: bold;
}




